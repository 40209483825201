import { useEffect, useState } from "react";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../../config/FirebaseConfig";
import { FaCheck, FaPlus } from "react-icons/fa";
import Layouts from "../../Layout/Layouts";

export default function MachineSelection() {
  const { packageid } = useParams();
  const [machines, setMachines] = useState([]);
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [bookingDetails, setBookingDetails] = useState({
    fromDate: "",
  });
  const userId = "d5d5d4d4448464s4"; // This should be dynamically set

  useEffect(() => {
    const fetchMachines = async () => {
      const cropPackageRef = doc(db, "crop_packages", packageid);
      const cropPackageDoc = await getDoc(cropPackageRef);

      if (cropPackageDoc.exists()) {
        const cropPackageData = cropPackageDoc.data();
        const machineCategories = cropPackageData.machines;

        const machinesQuery = query(
          collection(db, "machines"),
          where("category", "in", machineCategories)
        );

        const querySnapshot = await getDocs(machinesQuery);
        const machinesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMachines(machinesList);
      }
    };

    fetchMachines();
  }, [packageid]);

  const handleSelectMachine = (machine, price) => {
    setSelectedMachines((prev) =>
      prev.some((item) => item.id === machine.id)
        ? prev.filter((item) => item.id !== machine.id)
        : [...prev, { id: machine.id, name: machine.name, price }]
    );
  };

  const createOrder = async (machine, bookingDetails, userId) => {
    try {
      const orderId = generateOrderId(); // Generate a custom order ID
  
      const orderData = {
        orderId: orderId,
        userID: userId,
        ownerID: "ownerID", // Replace with actual ownerID if available
        machineID: machine.id,
        machineName: machine.name,
        totalPrice: machine.price,
        fromDate: bookingDetails.fromDate,
        toDate: bookingDetails.toDate || "", // Assuming you may set toDate as well
        status: "Pending",
        orderDate: new Date(),
  
        // Driver Details
        driverDetails: {
          driverName: "John Doe",
          driverNo: "john.doe@example.com",
          acceptedAt: new Date(), // Current date-time for acceptedAt
          actualArrival: new Date("2024-06-15T05:30:00+05:30"), // Example timestamp
        },
  
        // Cancellation Info
        cancellation: {
          cancelledAt: null,
          cancelledBy: "System",
          isCancelled: false,
          reason: "",
        },
  
        // Delivery Details
        deliveryDetails: {
          actualDeliveryDate: new Date("2024-06-15T05:30:00+05:30"), // Example timestamp
          deliveryStatus: "In Transit",
          estimatedDeliveryDate: new Date("2024-06-14T05:30:00+05:30"), // Example timestamp
        },
  
        // EMI Information
        emi: {
          dueAmount: 100,
          paidAmount: 50,
        },
  
        // Payment Info
        payment: {
          amount: "20000.00",
          method: "cod",
          status: "Pending",
          paymentToken: "paymentToken123",
        },
  
        // Provider Details
        providerDetails: {
          contactInfo: "provider@example.com",
          name: "Provider XYZ",
        },
  
        // Vehicle Details
        vehicleDetails: {
          color: "green",
          model: "Model X",
          plateNumber: "XYZ1234",
          type: "Truck",
          status: "Pending",
          supportContact: "support@example.com",
        },
  
        // Other Details
        totalDays: 8,
        totalHours: 120,
        paymentSchedules: [
          {
            amount: 50,
            dueDate: new Date("2024-08-01T05:30:00+05:30"), // Example timestamp
            paidAt: null,
            paymentMethod: "",
            paymentStatus: "Pending",
            totalAmount: 150,
            estimatedArrival: new Date("2024-06-14T05:30:00+05:30"), // Example timestamp
          },
        ],
  
        feedback: {
          providerComments: "Customer was cooperative.",
          providerRating: 4,
          userComments: "Great service!",
          userRating: 4.5,
        },
  
        incidentReports: [
          {
            date: new Date("2024-06-12T05:30:00+05:30"), // Example timestamp
            description: "Issue with delivery",
            resolved: false,
          },
        ],
  
        issues: [
          {
            description: "Machine malfunction",
            reportedAt: new Date("2024-06-13T05:30:00+05:30"), // Example timestamp
            resolved: false,
          },
        ],
      };
  
      // Use setDoc with a custom ID
      const orderRef = doc(db, "orders", orderId);
      await setDoc(orderRef, orderData);
      console.log("Order created with ID: ", orderId);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
  

  function generateOrderId() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}${month}${day}`; // Format as YYYYMMDD
    const randomNum = Math.floor(Math.random() * 1000); // Generate a random number between 0 and 999
  
    return `GTO${formattedDate}${String(randomNum).padStart(3, '0')}`; // Pad random number to ensure it has 3 digits
  }
  

  const handlePlaceOrder = () => {
    if (selectedMachines.length === 0) {
      alert("No machines selected for booking!");
      return;
    }

    const totalPrice = selectedMachines.reduce(
      (total, machine) => total + parseFloat(machine.price),
      0
    );

    let discount = 0;
    if (totalPrice > 10000) {
      discount = 2000; // Offer of ₹2000
    }

    const discountedPricePerMachine = discount / selectedMachines.length;

    const confirmation = window.confirm(
      `You have selected ${selectedMachines.length} machines. Total Price: ₹${totalPrice}. Do you want to place the order?`
    );

    if (confirmation) {
      selectedMachines.forEach((machine) => {
        const adjustedPrice =
          parseFloat(machine.price) - discountedPricePerMachine;
        createOrder(
          { ...machine, price: adjustedPrice },
          bookingDetails,
          userId
        );
      });

      // Clear selected machines and booking details after order placement
      setSelectedMachines([]);
      setBookingDetails({
        fromDate: "",
      });
    }
  };

  const totalPrice = selectedMachines.reduce(
    (total, machine) => total + parseFloat(machine.price),
    0
  );

  const discount = totalPrice > 10000 ? 2000 : 0;
  const finalPrice = totalPrice - discount;

  return (
    <Layouts>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">{machines.name}Package</h1>
        <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 px-4">
          {machines.map((machine) => (
            <MachineCard
              key={machine.id}
              machine={machine}
              onSelect={handleSelectMachine}
              isSelected={selectedMachines.some(
                (item) => item.id === machine.id
              )}
            />
          ))}
        </div>

        {/* Booking Details Input Fields */}
        <div className="mt-4">
          <h2 className="text-lg font-semibold">Booking Details</h2>
          <input
            type="date"
            placeholder="From Date"
            value={bookingDetails.fromDate}
            onChange={(e) =>
              setBookingDetails({ ...bookingDetails, fromDate: e.target.value })
            }
            className="border p-2 mr-2 w-full"
          />
        </div>
        <div className="bg-white p-4 rounded-lg  mt-4 border border-gray-300">
            <div className="">
              <h2 className="text-md font-semibold mb-2">Order Summary</h2>

              {/* Smaller Line above Total Price */}
              <div className="border-b border-gray-300 mb-2"></div>

              {/* Order Summary Details */}
              <div className="mb-1 justify-between flex">
                <p className="text-lg font-semibold">Subtotal</p>
                <p>{totalPrice}</p>
              </div>

              <div className="mb-1 text-green-500 justify-between flex">
                <p className="text-lg font-semibold">Discount</p>
                <p>{discount}</p>
              </div>

            

              {/* Separator Line below Total Price */}
              <div className="border-b border-gray-300 mb-2"></div>

              {/* Total Price */}
              <div className=" justify-between flex">
                <p className="text-lg font-semibold">Total Price</p>
                <p>{finalPrice}</p>
              </div>
            </div>
          </div>
        <div className="mt-6">
          <button
            onClick={handlePlaceOrder}
            className="mt-2 px-4 py-2 w-full bg-green-800 text-white rounded hover:bg-green-600 transition"
          >
            Place Order
          </button>
        </div>
      </div>
    </Layouts>
  );
}

function MachineCard({ machine, onSelect, isSelected }) {
  const { name, price, machineImage, category,pricingType,district } = machine;

  return (
    <div
      className={`flex border rounded-lg overflow-hidden cursor-pointer hover:bg-gray-50 transition ${
        isSelected ? "border-green-500" : "border-gray-200"
      }`}
      onClick={() => onSelect(machine, parseFloat(price))}
    >
      <div className="w-1/3 relative">
        <img
          className="w-full h-full object-cover"
          src={machineImage}
          alt={name}
        />
        <button
          onClick={(e) => {
            e.stopPropagation();
            onSelect(machine, parseFloat(price));
          }}
          className={`p-2 rounded-full absolute top-2 right-2 ${
            isSelected ? "bg-green-600" : "bg-gray-200"
          }`}
        >
          {isSelected ? (
            <FaCheck className="text-white" />
          ) : (
            <FaPlus className="text-black" />
          )}
        </button>
      </div>
      <div className="w-2/3 p-1 flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-start ">
              <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
              <div
                className={`text-xs font-medium p-0.5 bg-gray-200 rounded-lg `}
              >
             45
              </div>
            </div>
            <div className="flex flex-row justify-between items-center mx-2">
            <p className="text-xs text-gray-500 flex items-center">
              <svg
                className="w-3 h-3 mr-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              {district}
            </p>
             <p className="text-xs text-gray-500 flex items-center">

              {category}
            </p>
            </div>
          
          </div>
          <div className="flex justify-between items-center mt-3">
            <p className="text-lg font-bold text-gray-900">
              ₹{price}
              <span className="text-sm font-normal text-gray-600">/{pricingType}</span>
            </p>
           
          </div>
        </div>
    </div>
  );
}
