import React from "react";

function Footer() {
  return (
    <footer className=" hidden sm:block">
      <div className="py-3 bg-white shadow  bottom-0 ">
        <div className="mx-auto flex max-w-screen-xl flex-col gap-y-4 px-4 py-3 text-center text-gray-500 sm:px-20 lg:flex-row lg:justify-between lg:text-left xl:px-10">
          <p className="">© 2022 VPR Agro Spares | All Rights Reserved</p>
          {/* <p className="-order-1 sm:order-none">Made with ❤️ Mugesh Rao</p> */}
          <p className="-order-1 sm:order-none">Made with Auro Infra Solutions</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
