import React, { useState } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../config/FirebaseConfig';

function RequestDelete() {
  const [phonenumber, setPhonenumber] = useState('');
  const [userName, setUserName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCheckPhonenumber = async () => {
    setLoading(true);
    setMessage('');
    setUserName('');
    
    try {
      const q = query(collection(db, 'providers'), where('phonenumber', '==', phonenumber));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setMessage('Mobile number not found.');
      } else {
        const userDoc = querySnapshot.docs[0];
        setUserName(userDoc.data().username);
        setMessage('');
      }
    } catch (error) {
      console.error('Error checking mobile number:', error);
      setMessage('An error occurred while checking the mobile number.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    setLoading(true);
    setMessage('');

    try {
      const q = query(collection(db, 'providers'), where('phonenumber', '==', phonenumber));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        await deleteDoc(doc(db, 'providers', userDoc.id));
        setMessage('Account deleted successfully.');
        setUserName('');
        setPhonenumber('');
      } else {
        setMessage('Mobile number not found.');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      setMessage('An error occurred while deleting the account.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-xl font-semibold mb-4">Request Account Deletion</h1>
      <div className="mb-4">
        <label className="block text-gray-700">Mobile Number:</label>
        <input
          type="text"
          value={phonenumber}
          onChange={(e) => setPhonenumber(e.target.value)}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md"
          placeholder="Enter mobile number"
        />
      </div>
      <button
        onClick={handleCheckPhonenumber}
        className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        disabled={loading}
      >
        {loading ? 'Checking...' : 'Check Mobile Number'}
      </button>
      {userName && (
        <div className="mb-4">
          <p className="text-gray-700">Name: {userName}</p>
          <button
            onClick={handleDeleteAccount}
            className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Delete Account'}
          </button>
        </div>
      )}
      {message && <p className="text-red-500">{message}</p>}
    </div>
  );
}

export default RequestDelete;
