import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { IoCloseSharp } from "react-icons/io5";

const BottomSheet = ({ isOpen, onClose, children }) => {
  const sheetVariants = {
    hidden: { y: "100%", opacity: 0 },
    visible: { y: "0%", opacity: 1, transition: { duration: 0.3, ease: "easeInOut" } },
  };

  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 0.5 },
  };

  // <BottomSheet isOpen={isSheetOpen} onClose={closeSheet}>

  //       <p>This is the content of the bottom sheet.</p>

  //       <button onClick={closeSheet}>Close</button>
  //     </BottomSheet>

  // const [isSheetOpen, setSheetOpen] = useState(false);

  // const openSheet = () => {
  //   setSheetOpen(true);
  // };

  // const closeSheet = () => {
  //   setSheetOpen(false);
  // };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            className="fixed inset-0 bg-black"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={backdropVariants}
            onClick={onClose}
          ></motion.div>
          <motion.div
            className="fixed bottom-0 left-0 w-full z-50  flex items-center flex-col"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={sheetVariants}
          >
            <div className="cursor-pointer rounded-full bg-white  mb-3 p-1 border border-gray-400">
              <div className="flex items-center justify-center">
                <IoCloseSharp
                  onClick={onClose}
                  className="w-7 h-7 text-black"
                />
              </div>
            </div>
            <div className="bg-[#f3f4f6] rounded-t-2xl  p-4 shadow-md w-full">
              <div className="flex justify-center items-center mb-4">
                <span className="bg-green-600 rounded-full opacity-50 h-1.5 w-16"></span>
              </div>
              {children}
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default BottomSheet;
