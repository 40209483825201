import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "../../config/FirebaseConfig";
import Layouts from "../../Layout/Layouts";

export default function CropPackageList() {
  const [cropPackages, setCropPackages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCropPackages = async () => {
      const querySnapshot = await getDocs(collection(db, "crop_packages"));
      const data = querySnapshot.docs.map((doc) => doc.data());
      setCropPackages(data);
    };
    fetchCropPackages();
  }, []);

  return (
    <Layouts>
         <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
        <p className="font-semibold">Page Under Construction</p>
        <p className="text-sm">
          This page is currently under construction. The data displayed is for testing purposes only.
        </p>
      </div>

      {/* Available Crop Packages Title */}
      <div className="mb-4">
        <h2 className="text-xl font-semibold text-gray-800">Available Crop Packages</h2>
      </div>
      <div className="flex flex-wrap gap-4 p-2">
        {cropPackages.map((cropPackage) => (
          <CropPackageCard
            key={cropPackage.name}
            cropPackage={cropPackage}
            onClick={() => navigate(`/crop-packages/${cropPackage.id}`)}
          />
        ))}
      </div>
    </Layouts>
  );
}

function CropPackageCard({ cropPackage, onClick }) {
  return (
    <div
      onClick={onClick}
      className="bg-white rounded-lg my-2 shadow-sm overflow-hidden border border-gray-200 cursor-pointer"
    >
      <div className="flex">
        <div className="w-1/3 relative">
          <img
            src={cropPackage.image}
            alt={cropPackage.name}
            className="w-full h-full object-cover"
          />
          <div className="absolute top-2 left-2 bg-black bg-opacity-60 text-white text-xs font-medium px-2 py-1 rounded-full">
            Paddy
          </div>
        </div>
        <div className="w-2/3 p-1 flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-start ">
              <h3 className="text-lg font-semibold text-gray-800">
                {cropPackage.name}
              </h3>
              <div></div>
            </div>
            <div className="flex flex-row justify-between items-center mx-2">
              <p className="text-xs text-gray-500 flex items-center">
                <svg
                  className="w-3 h-3 mr-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                Cuddalore
              </p>
              <p className="text-xs text-gray-500 flex items-center">
                Season: {cropPackage.seasonStart} - {cropPackage.seasonEnd}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center mt-3">
            <p className="text-lg font-bold text-gray-900">
              ₹15000
              <span className="text-sm font-normal text-gray-600">/acre</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
