
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productId: null,
  productName: "",
  price: 0,
  location: "",
  img: "",
  acres: 0,
  date: "",
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderData: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearOrderData: (state) => initialState,
  },
});

export const { setOrderData, clearOrderData } = orderSlice.actions;
export default orderSlice.reducer;
