
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./Reducer/userSlice";
import OrderSlice from "./Reducer/OrderSlice";


// Middleware to save state to localStorage
const saveToLocalStorage = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();
  localStorage.setItem('state', JSON.stringify(state));
  return result;
};
const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) return undefined;  // No state saved
    return JSON.parse(serializedState);
  } catch (e) {
    console.error('Failed to load state:', e);
    return undefined;
  }
};
const rootReducer = combineReducers({
  user: userSlice,
   order: OrderSlice,
});


const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadFromLocalStorage(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saveToLocalStorage)
});


export default store;
