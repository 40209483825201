// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userProfile: null,
  isAuthenticated: false,
  token: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
      state.isAuthenticated = true; 
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.userProfile = null;
      state.isAuthenticated = false;
      state.token = null;
    },
    // Add other reducers as needed
  },
});

export const { setUserProfile, setToken, logout } = userSlice.actions;
export default userSlice.reducer;
