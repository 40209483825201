// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD_WEHvgLhPuagHewZHqwHkFaG26cBpKBU",
  authDomain: "growto.firebaseapp.com",
  projectId: "growto",
  storageBucket: "growto.appspot.com",
  messagingSenderId: "28142690298",
  appId: "1:28142690298:web:4aaeb3df35a34f06d68e43",
};

const app = initializeApp(firebaseConfig);

 const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
auth.settings.appVerificationDisabledForTesting = true;

export { auth };
