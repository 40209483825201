import { Link } from "react-router-dom";

const ProductCard = ({ product, selectedProduct, handleProductClick ,distance}) => {
  return (
    <div
      key={product.id}
      className={`bg-white rounded-lg my-2 shadow-sm overflow-hidden border border-gray-200 cursor-pointer ${
        selectedProduct && selectedProduct.id === product.id
          ? "ring-1 ring-green-800"
          : ""
      }`}
      onClick={() => handleProductClick(product)}
    >
      <div className="flex">
        <div className="w-1/3 relative">
          <img
            src={product.machineImage}
            alt={product.name}
            className="w-full h-full object-cover"
          />
          <div className="absolute top-2 left-2 bg-black bg-opacity-60 text-white text-xs font-medium px-2 py-1 rounded-full">
            {product.industry}
          </div>
        </div>
        <div className="w-2/3 p-4 pl-2 flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-start">
              <h3 className="text-lg font-semibold text-gray-800">
                {product.name}
              </h3>
              <div
                className={`text-xs font-medium p-0.5 bg-gray-200 rounded-lg `}
              >
         {distance.toFixed(0)} km
              </div>
            </div>
            <div className="flex flex-row justify-between items-center my-2">
              <p className="text-xs text-gray-500 flex items-center">
                <svg
                  className="w-3 h-3 mr-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                {product.district}
              </p>
              <p className="text-xs text-gray-500 flex items-center">
                {product.category}
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center mt-3">
            <p className="text-lg font-bold text-gray-900">
              ₹{product.price}
              <span className="text-sm font-normal text-gray-600">
                /{product.pricingOption}
              </span>
            </p>
            <Link
              to={`/equpiments/${product.id}`}
              className="bg-green-800 hover:bg-green-900 text-center text-white text-sm font-medium py-1 px-3 rounded-lg"
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
