import React, { useState, useEffect } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../config/FirebaseConfig';

const OTPVerification = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Initialize reCAPTCHA only once when the component mounts
    useEffect(() => {
        setupRecaptcha(); // Initialize reCAPTCHA
    }, []);

    // Function to initialize reCAPTCHA
    const setupRecaptcha = () => {
        try {
            if (!window.recaptchaVerifier) {
                window.recaptchaVerifier = new RecaptchaVerifier(
                    auth,                   // Firebase auth instance
                    'recaptcha-container',   // Element ID for reCAPTCHA
                    {
                        size: 'invisible',  // Invisible reCAPTCHA
                        callback: (response) => {
                            console.log('reCAPTCHA solved:', response);
                        },
                    }
                );
            }
        } catch (error) {
            console.error('Error initializing RecaptchaVerifier:', error);
        }
    };

    // Request OTP for phone verification
    const requestOTP = async (e) => {
        e.preventDefault();

        // Basic validation for phone number
        if (!phoneNumber.match(/^\+?\d{10,}$/)) {
            alert('Please enter a valid phone number with country code (e.g., +91xxxxxxxxxx).');
            return;
        }

        setIsLoading(true); // Set loading state to true

        try {
            // Ensure reCAPTCHA is rendered before calling signInWithPhoneNumber
            if (window.recaptchaVerifier) {
                const result = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
                setConfirmationResult(result);
                alert('OTP sent successfully!');
            } else {
                alert('reCAPTCHA not initialized properly.');
            }
        } catch (error) {
            console.error('Error during signInWithPhoneNumber:', error);

            if (error.code === 'auth/phone-number-is-not-verified') {
                alert('Phone number is not verified.');
            }

            if (error.message.includes('appVerificationDisabledForTesting')) {
                alert('Phone authentication is disabled for testing. Please enable it in the Firebase console.');
            } else {
                alert(`Error: ${error.message}`);
            }

            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.render().then((widgetId) => {
                    // eslint-disable-next-line no-undef
                    grecaptcha.reset(widgetId);
                });
            }
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    // Verify the entered OTP
    const verifyOTP = async (e) => {
        e.preventDefault();

        // Check if OTP has been entered
        if (!verificationCode) {
            alert('Please enter the OTP.');
            return;
        }

        setIsLoading(true); // Set loading state to true

        try {
            // Confirm OTP using the confirmation result from earlier
            const result = await confirmationResult.confirm(verificationCode);
            alert('Phone number verified successfully!');
            console.log('User Info:', result.user);
        } catch (error) {
            console.error('Error while verifying OTP:', error);
            alert(`Error: ${error.message}`);
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: 'auto', padding: '20px', textAlign: 'center' }}>
            <h2>Phone Number Verification</h2>
            <div id="recaptcha-container"></div> {/* Container for invisible reCAPTCHA */}

            {!confirmationResult ? (
                // OTP request form if OTP hasn't been sent
                <form onSubmit={requestOTP}>
                    <input
                        type="tel"
                        placeholder="Enter phone number (+91xxxxxxxxxx)"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                    />
                    <button type="submit" disabled={isLoading} style={{ padding: '10px', width: '100%' }}>
                        {isLoading ? 'Sending OTP...' : 'Send OTP'}
                    </button>
                </form>
            ) : (
                // OTP verification form after OTP has been sent
                <form onSubmit={verifyOTP}>
                    <input
                        type="text"
                        placeholder="Enter OTP"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        required
                        style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                    />
                    <button type="submit" disabled={isLoading} style={{ padding: '10px', width: '100%' }}>
                        {isLoading ? 'Verifying OTP...' : 'Verify OTP'}
                    </button>
                </form>
            )}
        </div>
    );
};

export default OTPVerification;
