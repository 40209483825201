import React from "react";
import { FaFileAlt } from "react-icons/fa";
import Layouts from "../../Layout/Layouts";

const MyWallet = () => {
  return (
    <Layouts>
      <div className="bg-white w-full max-w-md  p-4">
      
        <div className="bg-green-100 text-green-700 rounded-lg p-4 mb-6 flex justify-between items-center">
          <span className="text-xl font-bold">Total Balance</span>
          <span className="text-2xl font-bold">₹3205</span>
         </div>

        <div className="flex justify-around mb-4">
          <button className="text-xl font-bold text-green-600 border-b-2 border-green-600 pb-2">
            Total Earning
          </button>
          <button className="text-xl font-bold text-gray-600 pb-2">
            Withdraw History
          </button>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
          {[...Array(7)].map((_, index) => (
            <div
              key={index}
              className="flex justify-between items-center mb-2 last:mb-0"
            >
              <div className="flex items-center">
                <FaFileAlt className="text-gray-600 mr-2" />
                <div>
                  <p className="text-gray-700">Admin commission debit</p>
                  <p className="text-gray-500">#ACR148856</p>
                </div>
              </div>
              <p
                className={`font-bold ${
                  index % 2 === 0 ? "text-green-600" : "text-red-600"
                }`}
              >
                {index % 2 === 0 ? "₹200" : "-₹200"}
              </p>
            </div>
          ))}
        </div>

        <button className="bg-green-500 text-white w-full rounded-lg py-3 mt-6">
          Withdraw
        </button>
      </div>
    </Layouts>
  );
};

export default MyWallet;
