import React from "react";
import Layouts from "../Layout/Layouts";

const DownloadPage = () => {
  return (
    <Layouts>
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <h1 className="text-2xl font-semibold mb-4">Download Our App</h1>
          <p className="text-gray-600 mb-6">
            Choose the app version you need below to download the APK file directly to your device.
          </p>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {/* GrowTo User App Download Button */}
            <a
              href="https://mugesh-rao.github.io/CashTrack/GrowToUser.apk"
              download="GrowToUser.apk"
              className="px-4 py-2 bg-white text-blue-600 border-2 border-blue-600 rounded-lg hover:bg-blue-100 transition duration-300"
            >
              Download GrowTo
            </a>

            {/* GrowTo Partner App Download Button */}
            <a
              href="https://mugesh-rao.github.io/CashTrack/GrowToPartner.apk"
              download="GrowToPartner.apk"
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-300"
            >
              Download GrowTo Partner
            </a>
          </div>
        </div>
      </div>
    </Layouts>
  );
};

export default DownloadPage;
