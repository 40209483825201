import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {QrReader} from 'react-qr-reader';
import { FaArrowLeft } from 'react-icons/fa';

const QRCodeScanner = () => {
    const [scannedData, setScannedData] = useState('No result');
    const navigate = useNavigate();
  
    const handleScan = (result, error) => {
      if (!!result) {
        setScannedData(result?.text);
        // Assuming the data contains the owner ID
        navigate(`/Provider/${result.text}`);
      }
  
      if (!!error) {
        console.info(error);
      }
    };
  return (
    <div className="relative h-screen w-full bg-gray-900 flex flex-col items-center justify-center">
      {/* Back Button */}
      <div className="absolute top-4 left-4 z-50">
        <button
          onClick={() => navigate(-1)}
          className="p-2 bg-white text-black rounded-full"
        >
          <FaArrowLeft size={20} />
        </button>
      </div>

      {/* QR Scanner with Front Camera */}
      <div className="relative z-20 border-8 border-white rounded-lg overflow-hidden w-72 h-72">
      <QrReader
          onResult={handleScan}
          style={{ width: '100%', height: '100%' }}
          constraints={{ facingMode: { exact: 'environment' } }}
        />
      </div>

      {/* Scanning Overlay */}
      <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
        <div className="bg-white opacity-50 w-3/4 h-1 mb-4"></div>
        <div className="bg-white opacity-50 w-3/4 h-1"></div>
      </div>

      {/* Scanning Text */}
      <div className="absolute bottom-10 z-20 text-white text-lg">
        Scan QR code to get owner ID
      </div>
    </div>
  );
};

export default QRCodeScanner;
