import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import Layouts from "../../Layout/Layouts";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { IoLocationOutline } from "react-icons/io5";
import { FaClock, FaRegHeart } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";
import { getFirestore, doc, setDoc } from "firebase/firestore"; // Firestore imports
import { GoogleMap, Marker } from "@react-google-maps/api";
import { db } from "../../config/FirebaseConfig";

const ChooseLocation = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null); // New state for selected location
  const autocompleteRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [currentLocation, setCurrentLocation] = useState(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA-Ojj5pyuB44-I7ZZxOUJgsnDTkaq9wfw",
    libraries: ["places"],
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(currentPos);
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const onPlaceChanged = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();
      if (place.formatted_address) {
        setSearchQuery(place.formatted_address);
        if (place.geometry && place.geometry.location) {
          const location = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          setSelectedLocation(location);
          setCurrentLocation(location);
          if (mapRef.current) {
            mapRef.current.panTo(location);
            mapRef.current.setZoom(14);
          }
        }
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim() === "") {
      setSuggestions([]);
      return;
    }

    if (autocompleteRef.current) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input: query }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSuggestions(predictions);
        } else {
          setSuggestions([]);
        }
      });
    }
  };

  const handleSelectSuggestion = (placeId) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if (place.geometry && place.geometry.location) {
          const location = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          setSelectedLocation(location);
          setCurrentLocation(location);
          setSearchQuery(place.formatted_address || "");
          setSuggestions([]);
          if (mapRef.current) {
            mapRef.current.panTo(location);
            mapRef.current.setZoom(14);
          }
        }
      }
    });
  };



  const handleAddLocation = async () => {
    if (selectedLocation && searchQuery) {
      // Initialize Firestore

      // Replace 'user-id' with the actual user ID
      const userDocRef = doc(db, "users", "jorWyLpKszfyMA1fjVFxJ9pJ2iL2");

      try {
        await setDoc(
          userDocRef,
          { location: { address: searchQuery, coordinates: selectedLocation } },
          { merge: true }
        );
        alert(`Location added: ${searchQuery}`);
      } catch (error) {
        console.error("Error adding location to Firestore:", error);
        alert("Failed to add location.");
      }
    } else {
      alert("Please select a location first.");
    }
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <Layouts>
      <div className="p-4">
        <form>
          <div className="relative">
            <div className="flex items-center justify-between bg-white p-2 rounded-xl border border-gray-300">
              <div className="flex flex-row items-center">
                <AiOutlineSearch className="text-xl text-gray-600 ml-2" />
                <Autocomplete
                  onLoad={(autocomplete) => {
                    autocompleteRef.current = autocomplete;
                  }}
                  onPlaceChanged={onPlaceChanged}
                >
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search Places"
                    className="bg-white flex-grow p-2 rounded-xl outline-none ml-2"
                  />
                </Autocomplete>
              </div>
              <div className="flex items-center ml-1 border-l-2 border-gray-300 pl-1">
                <FaClock className="text-xl text-black" />
                <MdArrowDropDown className="text-xl text-black ml-0.5" />
              </div>
            </div>
            {suggestions.length > 0 && (
              <ul className="absolute mt-2 bg-white border border-gray-300 rounded-md w-full max-h-60 overflow-auto z-50">
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion.place_id}
                    onClick={() => handleSelectSuggestion(suggestion.place_id)}
                    className="flex items-center justify-between p-2 hover:bg-gray-100 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3">
                        <IoLocationOutline className="w-6 h-6 text-blue-500" />
                      </div>
                      <div>
                        <p className="font-semibold">
                          {suggestion.description}
                        </p>
                      </div>
                    </div>
                    <button className="text-gray-400">
                      <FaRegHeart className="w-6 h-6" />
                    </button>
                  </div>
                ))}
              </ul>
            )}
          </div>
          <button type="submit" className="hidden">
            Search
          </button>
        </form>
      </div>
      <div className="h-96">
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={currentLocation}
            zoom={14}
            onLoad={(map) => (mapRef.current = map)}
          >
            {selectedLocation && (
              <Marker position={selectedLocation} ref={markerRef} />
            )}
          </GoogleMap>
        )}
      </div>
      <div className="p-3">
        <button
          onClick={handleAddLocation}
          className="mt-4 py-3 w-full px-4 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none"
        >
          Add Location
        </button>
      </div>
    </Layouts>
  );
};

export default ChooseLocation;
