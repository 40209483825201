import React, { useState } from "react";
import { FaShareAlt, FaGift, FaCopy, FaShare } from "react-icons/fa";
import Layouts from "../../Layout/Layouts";
import { FaMessage } from "react-icons/fa6";

const ReferEarn = () => {
  const [copied, setCopied] = useState(false);
  const referralCode = "GTU1253";

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Layouts>
      <div className=" h-screen p-4 font-sans">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm mx-auto">
          <div className="flex justify-center mb-4">
            <div className="bg-yellow-400 rounded-full p-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>

          <h2 className="text-xl font-bold text-center mb-4">
            Refer now & earn up to ₹500
          </h2>
          <p className="text-sm text-center mb-6">
            Send a referral link to your friends via Whatsapp/ Facebook / Email
          </p>

          <div className="border border-dashed border-gray-300 rounded p-3 mb-6">
            <p className="text-xs text-gray-500 mb-1">Referral Code</p>
            <p className="font-bold text-yellow-500">AMITXV500</p>
          </div>

          <h3 className="font-bold mb-4">How does it work?</h3>

          <div className="space-y-4 mb-6">
            <div className="flex items-center">
              <div className="bg-blue-500 rounded p-2 mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
              </div>
              <p className="text-sm">
                Invite your friend to register on Growto
              </p>
            </div>
            <div className="flex items-center">
              <div className="bg-green-500 rounded p-2 mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <p className="text-sm">
                When your friend registers on App & Book a any Machine of you will get ₹100
                worth Reward Points
              </p>
            </div>
            <div className="flex items-center">
              <div className="bg-red-500 rounded p-2 mr-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 5a3 3 0 015-2.236A3 3 0 0114.83 6H16a2 2 0 110 4h-5V9a1 1 0 10-2 0v1H4a2 2 0 110-4h1.17C5.06 5.687 5 5.35 5 5zm4 1V5a1 1 0 10-1 1h1zm3 0a1 1 0 10-1-1v1h1z"
                    clipRule="evenodd"
                  />
                  <path d="M9 11H3v5a2 2 0 002 2h4v-7zM11 18h4a2 2 0 002-2v-5h-6v7z" />
                </svg>
              </div>
              <p className="text-sm">
                Reward Points can be used on your next order
              </p>
            </div>
          </div>

          <button className="w-full bg-purple-600 text-white py-3 rounded-lg font-bold">
            REFER NOW
          </button>
        </div>
      </div>
    </Layouts>
  );
};

export default ReferEarn;
